import { Component, NgZone } from '@angular/core';
import { Platform } from '@ionic/angular';
import { LanguageService } from './language.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { register } from 'swiper/element/bundle';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Router } from '@angular/router';

register();
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private langService : LanguageService,
    private platform: Platform,
    private http : HttpClient,
    private zone: NgZone,
    private router : Router
  ) {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

    this.toggleDarkTheme(prefersDark.matches);

    // Listen for changes to the prefers-color-scheme media query
    prefersDark.addListener((mediaQuery) => this.toggleDarkTheme(mediaQuery.matches));
    this.initializeApp();
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
          // Example url: https://beerswift.app/tabs/tab2
          // slug = /tabs/tab2
          const slug = event.url.split(".app").pop();
          if (slug) {
              this.router.navigateByUrl(slug);
          }
          // If no match, do nothing - let regular routing
          // logic take over
      });
  });
  }
  toggleDarkTheme(shouldAdd) {
    document.body.classList.toggle('dark', shouldAdd);
  }

  initializeApp() {
    this.platform.ready().then(() => {

      this.langService.setInitialAppLanguage();
    });
  }
}
